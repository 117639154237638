import defaults from 'DEFAULTS';

export default {
  default: {
    name: 'Dashboard',
  },
  top: [
    {
      name: 'Welcome! You have signed in successfully.',
      path: '',
      icon: 'home',
    },
    {
      name: 'overview',
      path: 'overview',
      active: true,
      icon: 'unordered-list',
    },
    {
      name: 'wallets',
      path: 'wallets',
      icon: 'wallet',
      active: !defaults.variables.external_payments,
      subItems: [
        {
          name: 'accounts',
          path: 'wallets/accounts',
          active: false,
          icon: 'bank',
        },
        {
          name: 'vaults',
          path: 'wallets/vaults',
          active: !defaults.variables.external_payments,
          icon: 'credit-card',
        },
      ],
    },
    {
      name: 'transactions',
      path: 'transactions',
      active: true,
      icon: 'swap',
    },
    {
      name: 'beneficiaries',
      path: 'beneficiaries',
      active: defaults.variables.external_payments,
      icon: 'contacts',
    },
    {
      name: 'cards',
      path: 'cards',
      icon: 'credit-card',
    },
    {
      name: 'analytics',
      path: 'analytics',
      icon: 'line-chart',
    },
    {
      name: 'price_plan',
      path: 'price-plan',
      icon: 'tags',
    },
    {
      name: 'help',
      path: 'help',
    },
    {
      name: 'pricing_plans',
      path: 'pricing',
      icon: 'euro',
      active: true,
    },
    {
      name: 'notifications',
      path: 'notifications',
      icon: 'notification',
      active: true,
    },
  ],
  bottom: [
    {
      name: 'personal_profile',
      icon: 'user',
      active: true,
      path: 'profile',
    },
    {
      name: 'logout',
      exit: true,
      icon: 'logout',
      active: true,
    },
  ],
  additional: [
    {
      name: 'messages',
      icon: 'message',
      active: true,
      path: 'requests',
    },
  ],
};
